import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
const BookCallBannerController = dynamic(() => import('./BookCallBanner/BookCallBanner.controller'))
import MobileNavbarBannerManager from '@/Logic/ClientLogic/Services/MobileNavbarBannerManager'
MobileNavbarBannerManager.getInstance()

const MobileNavbarBannerService = () => {
    const [isBannerActive, setIsBannerActive] = useState(false)
    const router = useRouter()

    MobileNavbarBannerManager.instance.initialiseSetShowBanner(setIsBannerActive)


    const PageNavBarBanners = {
    };

    const BannerController = PageNavBarBanners[router.basePath || router.pathname] || BookCallBannerController;

    return (
        <>
            {
                isBannerActive ?
                    <>
                        {BannerController ? (
                            <BannerController />
                        ) : null
                        }
                    </> : null
            }
        </>
    )
}

export default MobileNavbarBannerService