class MobileNavbarBannerManager {
  private constructor() {}
  public static instance: MobileNavbarBannerManager;
  public setShowBanner(showBanner: boolean): void {}

  public static getInstance(): void {
    if (!this.instance) {
      this.instance = new this();
    }
  }

  initialiseSetShowBanner(setShowBanner: () => {}): void {
    this.setShowBanner = setShowBanner;
  }
}

export default MobileNavbarBannerManager;
